import React, { useState } from 'react';
import { Button, Modal,ModalVariant, Form, FormGroup, TextInput } from '@patternfly/react-core';
import { PlusCircleIcon } from '@patternfly/react-icons';

const AddLinkModal = ({ onAdd, categoryId }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [linkName, setLinkName] = useState('');
  const [linkUrl, setLinkUrl] = useState('');

  const handleToggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const token = localStorage.getItem('token');

  const handleAddClick = () => {
    const linkData = { link_name: linkName, link_url: linkUrl, link_category_id: categoryId };
    // Voeg hier je fetch aanroep toe voor het opslaan van de link

    fetch(process.env.REACT_APP_API_URL+'/links', { // Vervang dit door je daadwerkelijke API endpoint
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': token,
    },
    body: JSON.stringify(linkData),
  })
  .then(response => {
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return response.json();
  })
  .then(data => {
    console.log('Success:', data);
    setIsModalOpen(false); // Sluit de modal
    // Reset velden
    setLinkName('');
    setLinkUrl('');

    onAdd(data); // Roep de onAdd prop aan met de responsedata als de nieuwe link succesvol is toegevoegd
  })
  .catch((error) => {
    console.error('Error:', error);
  });


  };

  return (
    <>
      <Button variant="link"  onClick={handleToggleModal} icon={<PlusCircleIcon />}> link </Button>
      <Modal
        title="Voeg een nieuwe link toe"
        variant={ModalVariant.small}

        isOpen={isModalOpen}
        onClose={handleToggleModal}
        actions={[
          <Button key="cancel" variant="secondary" onClick={handleToggleModal}>Annuleren</Button>,
          <Button key="confirm" variant="primary" onClick={handleAddClick}>Toevoegen</Button>
        ]}
      >
        <Form>
          <FormGroup label="Link naam" isRequired fieldId="link-name">
            <TextInput
              isRequired
              type="text"
              id="link-name"
              value={linkName}
              onChange={e => setLinkName(e.target.value)}
            />
          </FormGroup>
          <FormGroup label="Link URL" isRequired fieldId="link-url">
            <TextInput
              isRequired
              type="url"
              id="link-url"
              value={linkUrl}
              onChange={e => setLinkUrl(e.target.value)}
            />
          </FormGroup>
        </Form>
      </Modal>
    </>
  );
};

export default AddLinkModal;
