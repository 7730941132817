import React from 'react';
import SapGroupsComponent from '../sap_groups/SapGroupsComponent';

function App() {
  return (
    <div>
      <SapGroupsComponent />
    </div>
  );
}

export default App;
