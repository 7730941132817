import React, { useState } from 'react';
import { Button, Modal, ModalVariant } from '@patternfly/react-core';

const ImportData = ({onImport}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);

  const handleModalToggle = () => setIsModalOpen(!isModalOpen);

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const token = localStorage.getItem('token');

  const handleFileUpload = () => {
    const formData = new FormData();
    formData.append("file", selectedFile);

    fetch(process.env.REACT_APP_API_URL+'/links/import', {
      method: 'POST',
      headers: { 
        'Authorization': token
        },
      body: formData,
    })
    .then(response => response.json())
    .then(data => {
      console.log('Success:', data);
      onImport();
      handleModalToggle();
    })
    .catch(error => {
      console.error('Error:', error);
    });
  };

  return (
    <>
      <Button variant="plain" onClick={handleModalToggle}>Import Data</Button>
      <Modal
        variant={ModalVariant.small}
        title="Import Data"
        isOpen={isModalOpen}
        onClose={handleModalToggle}
        actions={[
          <Button key="cancel" variant="secondary" onClick={handleModalToggle}>Cancel</Button>,
          <Button key="import" variant="primary" onClick={handleFileUpload}>Import</Button>,
        ]}
      >
        <input type="file" onChange={handleFileChange} />
      </Modal>
    </>
  );
};

export default ImportData;
