import React, { useState, useEffect } from 'react';
import { PageSection, Title } from '@patternfly/react-core';
import JobChart from './JobChart'; // Assuming this path is correct

// Assuming jobs data structure comes from a higher-level component or API call
// const jobs = [
//   { name: "sap-aws-1", branch: "dev" },
//   { name: "build-jenkins-runner" },
//   { name: "build-awx-runner" }
// ];

const baseUrl = process.env.REACT_APP_API_URL+"/jenkins-api/job/";
const token = localStorage.getItem('token');

const Dashboard = () => {
  const [jobs, setJobs] = useState([]);

  useEffect(() => {
    const fetchPipelines = async () => {
      const infoUrl = `${process.env.REACT_APP_API_URL}/pipelines`; // Assuming this URL returns the list of jobs/pipelines
      try {
        const response = await fetch(infoUrl,
          {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': token,
            }
          });
        const pipelines = await response.json();
        setJobs(pipelines.map(pipeline => ({
          id: pipeline.pipeline_id,
          name: pipeline.pipeline_name,
          branch: pipeline.pipeline_branch
        })));
      } catch (error) {
        console.error('Error fetching pipelines:', error);
      }
    };

    fetchPipelines();
  }, []);

  return (
    <>
      <PageSection>
        <Title headingLevel="h1" size="lg">Dashboard Page Title!</Title>
      </PageSection>
      <PageSection>
        {jobs.map(job => (
          <JobChart key={job.name} 
            jobName={job.name} 
            whatBranch={job.branch} 
            baseUrl={baseUrl} 
          />
        ))}
      </PageSection>
    </>
  );
};

export default Dashboard;
