// src/App.js

import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { AuthProvider } from './AuthContext';
import LandingPage from './pages/LandingPage';
import AppLoginPage from './pages/LoginPage';
import RegisterPage from './pages/RegisterPage';
import BeveiligdeRoute from './pages/BeveiligdeRoute';
import SapGroupsPage from './pages/SapGroupsPage';
import Dashboard from './Dashboard/Dashboard';
import Links from './links/Links'; // Vergeet niet om deze component te maken
import '@patternfly/react-core/dist/styles/base.css';


function App() {
  return (
    <AuthProvider>
      <Router>
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/login" element={<AppLoginPage />} />
          <Route path="/register" element={<RegisterPage />} />
          <Route path="/beveiligdeRoute" element={<BeveiligdeRoute />} />
          <Route path="/links" element={<Links />} />
          <Route path="/sap_groups" element={<SapGroupsPage />} />
          <Route path="/dashboard" element={<Dashboard />} />
          
        </Routes>
      </Router>
    </AuthProvider>
  );
}

export default App;
