import React, { useState, useEffect } from 'react';
import { ChartBar, Chart, ChartAxis, ChartGroup } from '@patternfly/react-charts';
import { Title } from '@patternfly/react-core';

function JobChart({ jobName, baseUrl, whatBranch }) {
  const [chartDataSuccess, setChartDataSuccess] = useState([]);
  const [chartDataFailure, setChartDataFailure] = useState([]);
  const [isMultiBranch, setIsMultiBranch] = useState(false);
  const [description, setDescription] = useState('');

  useEffect(() => {
    const fetchJobInfo = async () => {
      const infoUrl = `${baseUrl}${jobName}/api/json?tree=_class,description`;
      try {
        const response = await fetch(infoUrl);
        const { _class, description } = await response.json();
        setIsMultiBranch(_class.includes("WorkflowMultiBranchProject"));
        setDescription(description || 'Geen omschrijving beschikbaar.');
      } catch (error) {
        console.error('Error fetching job info for', jobName, ':', error);
      }
    };

    const fetchData = async () => {
      await fetchJobInfo(); // Zorg ervoor dat we eerst de jobinfo ophalen
      const jobPath = isMultiBranch ? `${jobName}/job/${whatBranch}` : jobName;
      const buildsUrl = `${baseUrl}${jobPath}/api/json?tree=builds[number,duration,result,timestamp,result]`;
      console.log(buildsUrl)
      try {
        const response = await fetch(buildsUrl);
        const data = await response.json();
        const now = new Date();
        let monthlyCountsSuccess = new Array(12).fill(0);
        let monthlyCountsFailure = new Array(12).fill(0);

        data.builds?.forEach(build => {
          const buildDate = new Date(build.timestamp);
          const diffMonths = now.getMonth() - buildDate.getMonth() + (12 * (now.getFullYear() - buildDate.getFullYear()));
          if (diffMonths < 12) {
            const monthIndex = (now.getMonth() - buildDate.getMonth() + 12) % 12;
            if (build.result === "SUCCESS") {
              monthlyCountsSuccess[monthIndex]++;
            } else if (build.result === "FAILURE") {
              monthlyCountsFailure[monthIndex]++;
            }
          }
        });

        const prepareChartData = (monthlyCounts) => monthlyCounts.map((count, index) => {
          const monthDate = new Date();
          monthDate.setMonth(now.getMonth() - index);
          const monthName = monthDate.toLocaleString('default', { month: 'short' });
          return { name: monthName, x: monthName, y: count };
        }).reverse();

        setChartDataSuccess(prepareChartData(monthlyCountsSuccess));
        setChartDataFailure(prepareChartData(monthlyCountsFailure));
      } catch (error) {
        console.error('Error fetching data for job', jobName, ':', error);
      }
    };

    fetchData();
  }, [jobName, baseUrl, isMultiBranch, whatBranch]);

  return (
    <div style={{ height: '250px', width: '600px', marginBottom: '2rem' }}>
      <Title headingLevel="h1" size="lg">
        {`${jobName} Build Results`}
      </Title>
      <div>{description}</div> {/* Toon de omschrijving van de job */}
      <Chart
        ariaDesc={`${jobName} number of successful and failed runs per month`}
        ariaTitle={`${jobName} Build results`}
        domainPadding={{ x: [20, 20] }}
        height={250}
        width={600}
        padding={{
          bottom: 50,
          left: 50,
          right: 50,
          top: 50,
        }}
      >
        <ChartAxis />
        <ChartAxis dependentAxis showGrid />
        <ChartGroup offset={11}>
          <ChartBar data={chartDataSuccess} barWidth={10} />
          <ChartBar data={chartDataFailure} barWidth={10} />
        </ChartGroup>
      </Chart>
    </div>
  );
}

export default JobChart;
