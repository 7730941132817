import React, { useState, useEffect } from 'react';

//import EllipsisVIcon from '@patternfly/react-icons/dist/esm/icons/ellipsis-v-icon';
//import {DashboardWrapper} from '@patternfly/react-core/src/demos/DashboardWrapper';
import CodeBranchIcon from '@patternfly/react-icons/dist/esm/icons/code-branch-icon';
import CodeIcon from '@patternfly/react-icons/dist/esm/icons/code-icon';
import CubeIcon from '@patternfly/react-icons/dist/esm/icons/cube-icon';
import { OutlinedHddIcon } from '@patternfly/react-icons';
  import {Button, DataList, DataListItem, DataListCell, DataListItemRow, DataListItemCells, Flex, FlexItem, MenuToggle, MenuToggleCheckbox, OverflowMenu, OverflowMenuControl, OverflowMenuItem, PageSection, PageSectionVariants, Pagination, Text, TextContent, TextVariants, Toolbar, ToolbarItem, ToolbarContent} from '@patternfly/react-core';

import data from './sap_groups.json'; // Pas het pad aan naar je JSON-bestand
import { Table, TableHeader, TableBody } from '@patternfly/react-table';

const SapGroupsComponent = () => {
    return (
        <DataList aria-label="List of SAP Groups">
          {data.sap_groups.map((group, index) => (
            <DataListItem key={index} aria-labelledby={`sap-group-item-${index}`}>
              <DataListItemRow>
                <DataListItemCells
                  dataListCells={[
                    <DataListCell isFilled={false} key="buttons1">
                      <Flex direction={{
    default: 'column'
  }}>
                        <FlexItem>
                          <Text component={TextVariants.p}>Group ID: {group.gid}</Text>
                        </FlexItem>
                        <FlexItem>
                          <Text component={TextVariants.small}>
                            Working repo for
                            <a href="http://www.patternfly.org/">PatternFly</a>
                          </Text>
                        </FlexItem>
                        <FlexItem>
                          <Flex spaceItems={{
    default: 'spaceItemsSm'
  }}>
                            <FlexItem>
                              <CodeBranchIcon /> {group.hosts.length} host
                            </FlexItem>
                            <FlexItem>
                              <OutlinedHddIcon /> 5
                            </FlexItem>
                            <FlexItem> 
                            Hosts: {group.hosts.map(host => `ID: ${host.id}, Place: ${host.place}, Template: ${host.template}`).join('; ')}
                            </FlexItem>
                          </Flex>
                        </FlexItem>
                      </Flex>
                    </DataListCell>, <DataListCell isFilled={false} alignRight key="secondary content align">
                      <Flex>
                        <FlexItem>
                          <Button variant="secondary">Action</Button>
                        </FlexItem>
                        <FlexItem>
                          <a href="#">Link</a>
                        </FlexItem>
                      </Flex>
                    </DataListCell>

                    // <DataListCell key="primary content">
                    //   <span id={`sap-group-item-${index}`}>Group ID: {group.gid}</span>
                    // </DataListCell>,
                    // <DataListCell key="secondary content">
                    //   Hosts: {group.hosts.map(host => `ID: ${host.id}, Place: ${host.place}, Template: ${host.template}`).join('; ')}
                    // </DataListCell>
                  ]}
                />



              </DataListItemRow>
            </DataListItem>
          ))}
        </DataList>
      );
};

export default SapGroupsComponent;
