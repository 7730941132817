// src/LoginPage.js

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../AuthContext';
import { 
  Card, 
  CardBody,
  CardHeader, 
  Button, 
  Form, 
  FormGroup, 
  TextInput,
  Title
} from '@patternfly/react-core';

const RegisterPage = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const { login } = useAuth();

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Voeg hier je logica toe om met de backend te communiceren
    const response = await fetch(process.env.REACT_APP_API_URL+'/auth/register', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ username, password }),
    });

    if (response.ok) {
      // const { token } = await response.json();
      // login(token); // Sla de token op
      
      navigate('/login'); // Navigeer naar een beveiligde pagina
    } else {
      alert('Register mislukt');
    }
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      <Card>
        <CardHeader>
              <Title headingLevel="h1" size="lg">Registreren</Title>
            </CardHeader>
            <CardBody>

        <Form style={{ width: '300px' }} onSubmit={handleSubmit}>
        <FormGroup label="Gebruikersnaam" isRequired fieldId="username">
          <TextInput
            isRequired
            type="text"
            id="username"
            name="username"
            value={username}
            onChange={e => setUsername(e.target.value)}

          />
        </FormGroup>
        <FormGroup label="Wachtwoord" isRequired fieldId="password">
          <TextInput
            isRequired
            type="password"
            id="password"
            name="password"
            value={password}
            onChange={e => setPassword(e.target.value)}

          />
        </FormGroup>
        <Button variant="primary" type="submit">Register</Button>
      </Form>
      </CardBody>
      </Card>
    </div>
  );
};

export default RegisterPage;
