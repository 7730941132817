import React, { useState } from 'react';
import { Modal, ModalVariant, Button, Form, FormGroup, TextInput, PageSection, Title } from '@patternfly/react-core';
import { PlusCircleIcon } from '@patternfly/react-icons';
import { useNavigate } from 'react-router-dom';

const AddCategoryModal = ({ onAddCategory }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [categoryName, setCategoryName] = useState('');

  const handleModalToggle = () => setIsModalOpen(!isModalOpen);

  const token = localStorage.getItem('token');
  let navigate = useNavigate();

  const handleAddCategory = () => {
      // Veronderstel dat hier een API call komt om de categorie toe te voegen
      const linkCategoryData = {
        link_category_name: categoryName,
      };
      
      fetch(process.env.REACT_APP_API_URL+'/categories', { // Vervang dit door je daadwerkelijke API endpoint
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
        },
        body: JSON.stringify(linkCategoryData),
      })
    .then(response => {
      if (response.status === 401) {
        // Token is niet geldig of verlopen, redirect naar login
        navigate('/login');
      } else if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    })
    .then(data => {
      // Verwerk de beveiligde data
      console.log('Success:', data);
      handleModalToggle();
      onAddCategory(data);
      setCategoryName(''); // Reset het invoerveld
      setIsModalOpen(false); // Sluit de modal
    })
    .catch((error) => {
      console.error('Error:', error);
    });
  };


 // };

  return (
    <>
      <Button variant="link" onClick={handleModalToggle} icon={<PlusCircleIcon />}>categorie</Button>
      <Modal
        variant={ModalVariant.small}
        title="Voeg een nieuwe categorie toe"
        isOpen={isModalOpen}
        onClose={handleModalToggle}
        actions={[
          <Button key="cancel" variant="secondary" onClick={handleModalToggle}>Annuleren</Button>,
          <Button key="confirm" variant="primary" onClick={handleAddCategory}>Opslaan</Button>
        ]}
      >
        <Form>
          <FormGroup label="Categorienaam" isRequired fieldId="category-name">
            <TextInput
              isRequired
              type="text"
              id="category-name"
              name="category-name"
              value={categoryName}
              onChange={(e) => setCategoryName(e.target.value)} // Correcte event handler
              />
          </FormGroup>
        </Form>
      </Modal>
    </>
  );
};

export default AddCategoryModal;
