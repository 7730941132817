import React, { useState } from 'react';
import { Button, Modal, TextInput, Form, FormGroup } from '@patternfly/react-core';
import { ExclamationTriangleIcon } from '@patternfly/react-icons';
import { PencilAltIcon } from '@patternfly/react-icons';

const EditLinkModal = ({ onUpdate, onDelete, link }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const [linkName, setLinkName] = useState(link.link_name);
  const [linkUrl, setLinkUrl] = useState(link.link_url);

  const handleModalToggle = () => setIsModalOpen(!isModalOpen);
  const handleConfirmToggle = () => setIsConfirmOpen(!isConfirmOpen);

  const token = localStorage.getItem('token');

  const handleUpdateClick = () => {
    const updatedLinkData = {
      link_id: link.link_id,
      link_name: linkName,
      link_url: linkUrl,
      link_category_id: link.link_category_id,
    };

    fetch(`${process.env.REACT_APP_API_URL}/links/${link.link_id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token,
      },
      body: JSON.stringify(updatedLinkData),
    })
    .then(response => response.json())
    .then(data => {
      setIsModalOpen(false);
      onUpdate(updatedLinkData);
    })
    .catch(error => {
      console.error('Error:', error);
    });
  };

  const handleDeleteClick = () => {

    const updatedLinkData = {
        link_id: link.link_id,
        link_name: linkName,
        link_url: linkUrl,
        link_category_id: link.link_category_id,
      };
  
      fetch(`${process.env.REACT_APP_API_URL}/links/${link.link_id}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
        },
        body: JSON.stringify(updatedLinkData),
      })
      .then(response => response.json())
      .then(data => {
        setIsConfirmOpen(false); // Sluit de bevestigingsdialoog
        setIsModalOpen(false); // Sluit de hoofdmodal
        onDelete(link.link_id); // Voer de verwijderactie uit

      })
      .catch(error => {
        console.error('Error:', error);
      });


  };

  return (
    <>
      <Button variant="plain" onClick={handleModalToggle}><PencilAltIcon /></Button>

      <Modal
        title="Link bewerken"
        variant="small"

        isOpen={isModalOpen}
        onClose={handleModalToggle}
        actions={[
          <Button key="cancel" variant="secondary" onClick={handleModalToggle}>Annuleren</Button>,
          <Button key="save" variant="primary" onClick={handleUpdateClick}>Opslaan</Button>,
          <Button key="confirmDelete" variant="link" onClick={handleConfirmToggle}>Verwijderen</Button>,
        ]}
      >
        <Form>
          <FormGroup label="Link naam" isRequired fieldId="link-name">
            <TextInput
              isRequired
              type="text"
              id="link-name"
              value={linkName}
              onChange={e => setLinkName(e.target.value)}
            />
          </FormGroup>
          <FormGroup label="Link URL" isRequired fieldId="link-url">
            <TextInput
              isRequired
              type="url"
              id="link-url"
              value={linkUrl}
              onChange={e => setLinkUrl(e.target.value)}
            />
          </FormGroup>
        </Form>
      </Modal>

      <Modal
        title="Bevestiging vereist"
        isOpen={isConfirmOpen}
        onClose={handleConfirmToggle}
        actions={[
          <Button key="cancelDelete" variant="secondary" onClick={handleConfirmToggle}>Annuleren</Button>,
          <Button key="confirmDelete" variant="danger" onClick={handleDeleteClick}>Verwijderen</Button>
        ]}
        variant="small"
      >
        <ExclamationTriangleIcon /> Weet je zeker dat je deze link wilt verwijderen?
      </Modal>
    </>
  );
};

export default EditLinkModal;
