import React from 'react';
import brandImg2 from '../liquid-cheese.svg';
import {LoginFooterItem, LoginForm, LoginMainFooterBandItem, LoginMainFooterLinksItem, LoginPage, ListItem, ListVariant} from '@patternfly/react-core';
import ExclamationCircleIcon from '@patternfly/react-icons/dist/esm/icons/exclamation-circle-icon';
import { useAuth } from '../AuthContext';
import { useNavigate, Link } from 'react-router-dom';

export const AppLoginPage = () => {
  const [showHelperText, setShowHelperText] = React.useState(false);
  const [username, setUsername] = React.useState('');
  const [isValidUsername, setIsValidUsername] = React.useState(true);
  const [password, setPassword] = React.useState('');
  const [isValidPassword, setIsValidPassword] = React.useState(true);
  const navigate = useNavigate();
  const { login } = useAuth();

  const handleUsernameChange = (_event, value) => {
    setUsername(value);
  };
  const handlePasswordChange = (_event, value) => {
    setPassword(value);
  };


  const onLoginButtonClick = async (event) => {
    event.preventDefault();
    setIsValidUsername(!!username);
    setIsValidPassword(!!password);
    setShowHelperText(!username || !password);

    // Voeg hier je logica toe om met de backend te communiceren
    const response = await fetch(process.env.REACT_APP_API_URL+'/auth/login', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ username, password }),
    });

    if (response.ok) {
      const { token } = await response.json();
      login(token); // Sla de token op
      
      navigate('/beveiligdeRoute'); // Navigeer naar een beveiligde pagina
    } else {
      alert('Inloggen mislukt');
    }


  };
  const signUpForAccountMessage = <LoginMainFooterBandItem>
      Nog geen account? <Link to={`/register`}>Registreer</Link>
    </LoginMainFooterBandItem>;

  const loginForm = <LoginForm 
          showHelperText={showHelperText} 
          helperText="Invalid login credentials." 
          helperTextIcon={<ExclamationCircleIcon />} 
          usernameLabel="Username" 
          usernameValue={username} 
          onChangeUsername={handleUsernameChange} 
          isValidUsername={isValidUsername} 
          passwordLabel="Password" 
          passwordValue={password} 
          isShowPasswordEnabled 
          onChangePassword={handlePasswordChange} 
          isValidPassword={isValidPassword} 
          onLoginButtonClick={onLoginButtonClick} 
          loginButtonLabel="Log in" />;
  
          return <LoginPage 
          className="--pf-v5-c-background-image--BackgroundImage: url('https://pcbomen.nl/wp-content/uploads/bfi_thumb/PCbomen-bomen-nezc7x58gh1h3zu5f89qs928ftrvj9mi4zvt44yy6s.png');"
            footerListVariants={ListVariant.inline} 
         //   brandImgSrc={brandImg2} 
            // brandImgAlt="PatternFly logo" 
            backgroundImgSrc="https://pcbomen.nl/wp-content/uploads/bfi_thumb/PCbomen-bomen-nezc7x58gh1h3zu5f89qs928ftrvj9mi4zvt44yy6s.png" 
           // textContent="This is placeholder text only. Use this area to place any information or introductory message about your application that may be relevant to users." 
            loginTitle="Log in to your account" 
         //   loginSubtitle="Enter your single sign-on LDAP credentials." 
            signUpForAccountMessage={signUpForAccountMessage} 
            //forgotCredentials={forgotCredentials}
            >
      {loginForm}
    </LoginPage>;
};

export default AppLoginPage;
