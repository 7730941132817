import React from 'react';
import { Button } from '@patternfly/react-core';
import { useNavigate } from 'react-router-dom';


const ExportData = () => {
    const token = localStorage.getItem('token');
    let navigate = useNavigate();
  
    const handleLinkClick = () => {

        fetch(process.env.REACT_APP_API_URL+'/links/export', { 
            method: 'GET',
            headers: { 
                'Content-Type': 'application/json',
                'Authorization': token
                } 
            })
            .then(response => {
                if (response.status === 401) {
                // Token is niet geldig of verlopen, redirect naar login
                navigate('/login');
                } else if (response.ok) {
                    return response.json();
                }
            })
            .then(data => {
                // Verwerk de beveiligde data
                const json = JSON.stringify(data);
                const blob = new Blob([json], {type: 'application/json'});
                const url = URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = 'export.json'; // De bestandsnaam van de download
                document.body.appendChild(a); // Voeg toe aan document om te kunnen klikken
                a.click(); // Simuleer klik om download te starten
                document.body.removeChild(a); // Verwijder anchor van document
                URL.revokeObjectURL(url); // Maak geheugen vrij
              })
                          .catch(error => {
                console.error('Er is een fout opgetreden', error);
            });
    };

  return (
    <>
      <Button variant="plain" onClick={() => handleLinkClick()}>Export</Button>

    </>
  );
};

export default ExportData;
