// src/components/BeveiligdeLayout.js

import React from 'react';
import {Avatar, Brand, Breadcrumb, BreadcrumbItem, Button, ButtonVariant, Card, CardBody, Divider, Dropdown, DropdownGroup, DropdownItem, DropdownList, Gallery, GalleryItem, Masthead, MastheadBrand, MastheadContent, MastheadMain, MastheadToggle, MenuToggle, MenuToggleElement, Nav, NavItem, NavList, Page, PageSection, PageSectionVariants, PageSidebar, PageSidebarBody, PageToggleButton, SkipToContent, Text, TextContent, Toolbar, ToolbarContent, ToolbarGroup, ToolbarItem} from '@patternfly/react-core';
import BarsIcon from '@patternfly/react-icons/dist/esm/icons/bars-icon';
import BellIcon from '@patternfly/react-icons/dist/esm/icons/bell-icon';
import CogIcon from '@patternfly/react-icons/dist/esm/icons/cog-icon';
import HelpIcon from '@patternfly/react-icons/dist/esm/icons/help-icon';
import QuestionCircleIcon from '@patternfly/react-icons/dist/esm/icons/question-circle-icon';
import EllipsisVIcon from '@patternfly/react-icons/dist/esm/icons/ellipsis-v-icon';
import imgAvatar from '@patternfly/react-core/src/components/assets/avatarImg.svg';
import pfLogo from '@patternfly/react-core/src/demos/assets/pf-logo.svg';
import { useAuth } from '../AuthContext';
import { useNavigate, Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';



function BeveiligdeLayout({ children }) {
  const [activeItem, setActiveItem] = React.useState(1);
  const onNavSelect = (_event, selectedItem) => {
    typeof selectedItem.itemId === 'number' && setActiveItem(selectedItem.itemId);
  };

  const { logout } = useAuth();

  let navigate = useNavigate();

  const handleLogout = () => {
    logout(); // Roept de logout functie uit AuthContext aan
    navigate('/'); // Omleidt de gebruiker naar de login pagina
  }

  const headerToolbar = <Toolbar id="toolbar" isFullHeight isStatic>
        <ToolbarContent>
          <ToolbarGroup variant="icon-button-group" align={{
      default: 'alignRight'
    }} spacer={{
      default: 'spacerNone',
      md: 'spacerMd'
    }}>
          <ToolbarItem>
            <Button aria-label="Notifications" variant={ButtonVariant.plain} icon={<BellIcon />} />
          </ToolbarItem>
          <ToolbarGroup variant="icon-button-group" visibility={{
              default: 'hidden',
              lg: 'visible'
            }}>
            <ToolbarItem>
              <Button aria-label="Settings" variant={ButtonVariant.plain} icon={<CogIcon />} />
            </ToolbarItem>
            <ToolbarItem>
              <Button aria-label="Help" variant={ButtonVariant.plain} icon={<QuestionCircleIcon />} />
            </ToolbarItem>
            <ToolbarItem>
              <Button aria-label="Exit" variant="plain" onClick={handleLogout}>Exit</Button>
          </ToolbarItem>
          </ToolbarGroup>
        </ToolbarGroup>
      </ToolbarContent>
    </Toolbar>;


const masthead = <Masthead>
  <MastheadToggle>
    <PageToggleButton variant="plain" aria-label="Global navigation">
      <BarsIcon />
    </PageToggleButton>
  </MastheadToggle>
  <MastheadMain>
  </MastheadMain>
  <MastheadContent>
    {headerToolbar}
    
    </MastheadContent>
</Masthead>;

const location = useLocation();

const navItems = [
  { to: '/beveiligdeRoute', name: 'Home', itemId: 0 },
  { to: '/links', name: 'Links', itemId: 1 },
  { to: '/dashboard', name: 'Dashboard', itemId: 2 },
// Voeg hier meer items toe indien nodig
];

const pageNav = <Nav>
<NavList>
  {navItems.map(item => (
    <NavItem key={item.itemId} itemId={item.itemId} isActive={location.pathname === item.to}>
      <Link to={item.to}>{item.name}</Link>
    </NavItem>
  ))}
</NavList>
</Nav>;

const sidebar = <PageSidebar>
  <PageSidebarBody>{pageNav}</PageSidebarBody>
</PageSidebar>;

  return (
    <Page header={masthead} 
    sidebar={sidebar} 
    isManagedSidebar 
    // skipToContent={pageSkipToContent} 
    //mainContainerId={mainContainerId} 
    isBreadcrumbWidthLimited breadcrumbProps={{
      stickyOnBreakpoint: {
        md: 'top'
      }
    }}>
        
        {children}
          
      </Page>
  );
}

// function BeveiligdeLayout({ children }) {
//   return (
//     <div>
//       <nav>
//         {/* Masthead code gaat hier. Je kunt een eenvoudige nav bar of een complexere component van je UI framework gebruiken */}
//         <ul>
//         <li><Link to="/beveiligdeRoute">Beveiligde Home</Link></li>
//         <li><Link to="/links">Links</Link></li>
//           {/* Voeg meer links toe naar beveiligde pagina's hier */}
//         </ul>
//       </nav>
//       <div>
//         {children}
//       </div>
//     </div>
//   );
// }

export default BeveiligdeLayout;
