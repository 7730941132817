import React, { useState, useEffect } from 'react';
import { TextContent, Text, Card, CardTitle, CardBody,CardFooter, Divider,PageSection, PageSectionVariants, Title, Gallery, GalleryItem } from '@patternfly/react-core';
import AddLinkModal from './AddLinkModal'; // Pas het pad aan naar waar je AddLinkPopover component zich bevindt
import EditLinkModal from './EditLinkModal'; // Pas het pad aan naar waar je AddLinkPopover component zich bevindt
import EditCategoryModal from './EditCategoryModal'; // Pas het pad aan naar waar je AddLinkPopover component zich bevindt
import AddCategoryModal from './AddCategoryModal'; // Pas het pad aan naar waar je AddLinkPopover component zich bevindt
import BeveiligdeLayout from '../components/BeveiligdeLayout';
import ExportData from './ExportData';
import ImportData from './ImportData';
import LinksList from './component/LinksList';
import { useNavigate } from 'react-router-dom';
import {Button, EmptyState, EmptyStateBody, EmptyStateActions, EmptyStateHeader, EmptyStateFooter, EmptyStateIcon} from '@patternfly/react-core';
import CubesIcon from '@patternfly/react-icons/dist/esm/icons/cubes-icon';
import {DragDropSort, DraggableObject} from '@patternfly/react-drag-drop';

export const EmptyStateBasic = () => <EmptyState>
    <EmptyStateHeader titleText="Leeg" headingLevel="h4" icon={<EmptyStateIcon icon={CubesIcon} />} />
    <EmptyStateBody>
      Er zijn nog geen categorien en nog geen links. 
    </EmptyStateBody>
    </EmptyState>;

const Links = () => {
  const [categories, setCategories] = useState([]);
  const [links, setLinks] = useState([]);


  const token = localStorage.getItem('token');
  let navigate = useNavigate();

  const handleAddCategory = (newCategory) => {
    setCategories([...categories, newCategory.data]);
  };
  const handleAddLink = (newLink) => {
//    setLinks([...links, newLink.data]);
    getLinks();
  };
  const handleEditLink = (editedLink) => {
    const updatedLinks = links.map(link =>
      link.link_id === editedLink.link_id ? editedLink : link
    );
//    setLinks(updatedLinks); // Update de staat met de bijgewerkte links
    getLinks();

  };
  const handleEditCategory = (editedCategory) => {
    const updatedCategories = categories.map(category =>
      category.link_category_id === editedCategory.link_category_id ? editedCategory : category
    );
    setCategories(updatedCategories); // Update de staat met de bijgewerkte links
  };
  const handleDeleteLink = (linkId) => {
    const updatedLinks = links.filter(link => link.link_id !== linkId);
//    setLinks(updatedLinks); // Update de staat met de gefilterde lijst
    getLinks();

  };
  const handleDeleteCategory = (categoryId) => {
    const updatedCategories = categories.filter(category => category.link_category_id !== categoryId);
    setCategories(updatedCategories); // Update de staat met de gefilterde lijst
  };
  const handleImport = () => {
    getLinks();
  };

  const handleDrop = (category_id, order) => {
    console.log('dropping')
  };

  
  const handleLinkClick = (linkId, linkCategoryId) => {
    fetch(process.env.REACT_APP_API_URL+'/link_clicks', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      },
      body: JSON.stringify({
        link_id: linkId,
        link_category_id: linkCategoryId,
      }),
    })
    .then(response => response.json())
    .then(data => console.log('Klik geregistreerd:', data))
    .catch(error => console.error('Fout bij het registreren van klik:', error));
  };
  

  const getLinks = () => {
    fetch(process.env.REACT_APP_API_URL+'/links', { 
      method: 'GET',
      headers: { 
          'Content-Type': 'application/json',
          'Authorization': token
        } 
      })
      .then(response => {
        if (response.status === 401) {
          // Token is niet geldig of verlopen, redirect naar login
          navigate('/login');
        } else if (response.ok) {
          return response.json();
        }
      })
      .then(data => {
        // Verwerk de beveiligde data
        setCategories(data.categories);
        // Zet de opgehaalde data om naar de gewenste structuur
        const formattedLinks = data.links.map(link => ({
          id: link.link_id, // Gebruik link_id als id
          content: <>
              <a href={link.link_url} target="_blank" onClick={() => handleLinkClick(link.link_id, link.link_category_id)}>
                {link.link_name}</a>
                <EditLinkModal link={link} onUpdate={handleEditLink} onDelete={handleDeleteLink} />
              </>,
          ...link // Behoud de rest van de eigenschappen
        }));

        setLinks(formattedLinks);
      })
      .catch(error => {
        console.error('Er is een fout opgetreden', error);
      });
  }

  useEffect(() => {
  getLinks();
  }, []);

  // Functie om links te groeperen per categorie
  const getLinksByCategoryId = (categoryId) => links.filter(link => link.link_category_id === categoryId);

  
  return (
    <>
    <BeveiligdeLayout>
    <PageSection variant={PageSectionVariants.light}>
      <TextContent>
        <Text component="h1">Links</Text>
        <Text component="p">
            <AddCategoryModal onAddCategory={handleAddCategory} />
            <ExportData />
            <ImportData onImport={handleImport}/>
        </Text>
      </TextContent>
    </PageSection>
    
      <PageSection>
        {categories.length > 0 ? (
          
          <Gallery hasGutter>
            {categories.map(category => (
              <GalleryItem key={category.link_category_id}>
                <Card>
                <CardTitle>
                  <Title headingLevel="h2" size="md">{category.link_category_name}</Title>
                </CardTitle>
                  <CardBody>
                  <LinksList
                    categoryId={category.link_category_id}
                    links={getLinksByCategoryId(category.link_category_id)}
                   // links={links}
                 //   onDrop={(newOrder) => handleDrop(category.link_category_id, newOrder)}
                  />
                  </CardBody>
                  <Divider />
                  <CardFooter>
                    <AddLinkModal onAdd={handleAddLink} categoryId={category.link_category_id} />
                    <EditCategoryModal onUpdate={handleEditCategory} onDelete={handleDeleteCategory} category={category} />
                  </CardFooter>
                </Card>
              </GalleryItem>
            ))}
          </Gallery>
        ) : (
          <EmptyStateBasic /> // Zorg ervoor dat je dit component correct implementeert of importeert
        )}
    </PageSection>
    </BeveiligdeLayout>
    </>
  );
};

export default Links;
