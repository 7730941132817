import React, { useState } from 'react';
import { Button, Modal, TextInput, Form, FormGroup } from '@patternfly/react-core';
import { ExclamationTriangleIcon } from '@patternfly/react-icons';
import { PencilAltIcon } from '@patternfly/react-icons';

const EditCategoryModal = ({ onUpdate, onDelete, category }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const [categoryName, setCategoryName] = useState(category.link_category_name);

  const handleModalToggle = () => setIsModalOpen(!isModalOpen);
  const handleConfirmToggle = () => setIsConfirmOpen(!isConfirmOpen);

  const token = localStorage.getItem('token');

  const handleUpdateClick = () => {
    const updatedCategoryData = {
      link_category_id: category.link_category_id,
      link_category_name: categoryName,
    };

    fetch(`${process.env.REACT_APP_API_URL}/categories/${category.link_category_id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token,
      },
      body: JSON.stringify(updatedCategoryData),
    })
    .then(response => response.json())
    .then(data => {
      setIsModalOpen(false);
      onUpdate(updatedCategoryData);
    })
    .catch(error => {
      console.error('Error:', error);
    });
  };

  const handleDeleteClick = () => {
    const updatedCategoryData = {
       category_id: category.link_category_id,
       category_name: categoryName,
      };
  
      fetch(`${process.env.REACT_APP_API_URL}/categories/${category.link_category_id}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
        },
        body: JSON.stringify(updatedCategoryData),
      })
      .then(response => response.json())
      .then(data => {
        setIsConfirmOpen(false); // Sluit de bevestigingsdialoog
        setIsModalOpen(false); // Sluit de hoofdmodal
        onDelete(category.link_category_id); // Voer de verwijderactie uit

      })
      .catch(error => {
        console.error('Error:', error);
      });
  };

  return (
    <>
      <Button variant="link" onClick={handleModalToggle} icon={<PencilAltIcon />}> categorie</Button>

      <Modal
        title="Category bewerken"
        variant="small"

        isOpen={isModalOpen}
        onClose={handleModalToggle}
        actions={[
          <Button key="cancel" variant="secondary" onClick={handleModalToggle}>Annuleren</Button>,
          <Button key="save" variant="primary" onClick={handleUpdateClick}>Opslaan</Button>,
          <Button key="confirmDelete" variant="link" onClick={handleConfirmToggle}>Verwijderen</Button>,
        ]}
      >
        <Form>
        <FormGroup label="Categorienaam" isRequired fieldId="category-name">
            <TextInput
              isRequired
              type="text"
              id="category-name"
              name="category-name"
              value={categoryName}
              onChange={(e) => setCategoryName(e.target.value)} // Correcte event handler
              />
          </FormGroup>
        </Form>
      </Modal>

      <Modal
        title="Bevestiging vereist"
        isOpen={isConfirmOpen}
        onClose={handleConfirmToggle}
        actions={[
          <Button key="cancelDelete" variant="secondary" onClick={handleConfirmToggle}>Annuleren</Button>,
          <Button key="confirmDelete" variant="danger" onClick={handleDeleteClick}>Verwijderen</Button>
        ]}
        variant="small"
      >
        <ExclamationTriangleIcon /> Weet je zeker dat je deze link wilt verwijderen?
      </Modal>
    </>
  );
};

export default EditCategoryModal;
