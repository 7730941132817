import { DragDropSort,DragDrop,Droppable,Draggable } from '@patternfly/react-drag-drop';
import React, { useState, useEffect } from 'react';



const LinksList = ({ categoryId, links, onDrop }) => {
  // Filter de links op basis van categoryId
// const categoryLinks = links.filter(link => link.link_category_id === categoryId);
 const token = localStorage.getItem('token');
const [llinks, setItems] = useState(links);

// setItems(ccategoryLinks);

//   const onnDrop = () => {
//     console.log('testdrop')
//     console.log(liknks)
    
            fetch(process.env.REACT_APP_API_URL+'/links/weights', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
            body: JSON.stringify({
                //link_category_id: category_id,
                links: llinks,
            }),
            })
            .then(response => response.json())
            .then(data => {
            // getLinks();
            
            console.log('Weights geregistreerd:', data)
            })
            .catch(error => console.error('Fout bij het registreren van klik:', error));

//   }


  return (
    <DragDropSort items={llinks} variant="defaultWithHandle" onDrop={(_, newItems) => {
      setItems(newItems);
      console.log('dropfunction')
      console.log(newItems)
      }} />
  );
};
  
export default LinksList;
