import React, {  useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useAuth } from '../AuthContext';
import BeveiligdeLayout from '../components/BeveiligdeLayout';
//import Links2 from '../links/Links2';
import {Avatar, Brand, Breadcrumb, BreadcrumbItem, Button, ButtonVariant, Card, CardBody, Divider, Dropdown, DropdownGroup, DropdownItem, DropdownList, Gallery, GalleryItem, Masthead, MastheadBrand, MastheadContent, MastheadMain, MastheadToggle, MenuToggle, MenuToggleElement, Nav, NavItem, NavList, Page, PageSection, PageSectionVariants, PageSidebar, PageSidebarBody, PageToggleButton, SkipToContent, Text, TextContent, Toolbar, ToolbarContent, ToolbarGroup, ToolbarItem} from '@patternfly/react-core';

const BeveiligdeRoute = () => {
  const { logout } = useAuth();

  let navigate = useNavigate();

  useEffect(() => {
    const fetchUserData = async () => {
      const token = localStorage.getItem('token');
      const response = await fetch(process.env.REACT_APP_API_URL+'/protected', { // Pas dit pad aan naar je werkelijke API-route
        headers: {
          'Authorization': token
        }
      });
      const data = await response.json();
      if (response.ok) {
        console.log(data)
      } else {
        // Afhandelen van fouten of omleiden naar loginpagina
        navigate('/login');
      }
    };

    fetchUserData();
  }, [navigate]);


  const handleLogout = () => {
    logout(); // Roept de logout functie uit AuthContext aan
    navigate('/login'); // Omleidt de gebruiker naar de login pagina
  }

  return (
    <BeveiligdeLayout>
      <PageSection variant={PageSectionVariants.light} isWidthLimited>
          <TextContent>
            <Text component="h1">Home</Text>
            <Text component="p">This is a full page demo.</Text>
          </TextContent>
        </PageSection>
        <PageSection isWidthLimited>
                        
                  <div style={{ textAlign: 'center', marginTop: '20px' }}>
                    <h1>Beveiligde Route</h1>
                    <Button onClick={handleLogout}>Logout</Button>
                    <Link to={`/links`}>

              Links
              </Link>
                  </div>
                  </PageSection>

        </BeveiligdeLayout>
  );
};

export default BeveiligdeRoute;
