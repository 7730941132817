import React from 'react';
import { Button, Title, PageSection, Text, TextContent } from '@patternfly/react-core';
import { useNavigate } from 'react-router-dom';
import { TextVariants} from '@patternfly/react-core';

console.log(process.env.REACT_APP_API_URL); // Voor debugging

const LandingPage = () => {
  let navigate = useNavigate();

  return (
    <>
    <PageSection className="headerSection">
<TextContent>
    <Text component={TextVariants.h1}>Hello World</Text>
  </TextContent>


     
        <Title headingLevel="h1" size="3xl">Welkom op onze website</Title>
        <TextContent>
          <Text component="p" className="introText">
            Ontdek een nieuwe manier om al jouw favoriete links op één plek bij te houden. 
            Maak je eigen lijsten, deel met vrienden of collega's, en blijf georganiseerd 
            met gemakkelijk toegankelijke bookmarks. Begin vandaag nog door in te loggen!
          </Text>
        </TextContent>
      </PageSection>

      <PageSection>
        <Button variant="primary" onClick={() => navigate('/login')}>
          Inloggen
        </Button>
      </PageSection>
      </>
  );
};

export default LandingPage;
